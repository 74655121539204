import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';


const Privacy = () => {
    return (
        <div>
            <Helmet>
                <title>Privacy Policy | Surfheim VPN - Your Data, Your Rights</title>
                <link rel="canonical" href="https://surfheim.com/Privacy-Policy/" />
                <meta name="description" content="Read Surfheim VPN's Privacy Policy to understand how we collect, use, and protect your personal information. Your privacy is our top priority." />
                <meta name="keywords" content="privacy policy, V2ray, Vmess, Vless, Xray, Surfheim VPN, data protection, user privacy, personal information, online security" />
                <meta property="og:title" content="Surfheim VPN Privacy Policy - Your Data, Your Rights" />
                <meta property="og:description" content="Explore Surfheim VPN's Privacy Policy. Learn how we safeguard your personal information and your rights regarding data privacy." />
                <meta property="og:image" content="%PUBLIC_URL%/privacy-image.png" />
                <meta property="og:url" content="https://surfheim.com/Privacy-Policy/" />
                <meta property="og:type" content="website" />
            </Helmet>

            <Header />
            <div className="md:container bg-light/25 mx-8 md:mx-auto px-4 py-8 rounded-xl my-8 text-justify font-secondary">
                <h1 className="text-4xl font-semibold mb-8 text-center">
                Privacy Policy
                </h1>
                <p className="text-gray-700 mb-8">
                At Surfheim, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you use our services. By using our VPN service, you consent to the practices described in this policy.
                </p>

                <h2 className="text-2xl font-semibold mb-4">Information We Collect</h2>
                <div className="text-gray-700 mb-8 ml-4">
                    <p className='font-normal mb-2'>We may collect various types of information from you when you use our services:</p>
                    <p><span className='font-semibold text-gray-900'>- Personal Information:</span>{" This includes your name, email address, payment information, and any other details you provide during registration or while using our services."}</p>
                    <p><span className='font-semibold text-gray-900'>- Usage Data:</span>{" We automatically collect information about how you interact with our service, including your IP address, device type, browser type, operating system, pages visited, and timestamps."}</p>
                    <p><span className='font-semibold text-gray-900'>- Cookies and Tracking Technologies:</span>{" We may use cookies and similar tracking technologies to enhance user experience and analyze website traffic."}</p>
                    
                </div>

                <h2 className="text-2xl font-semibold mb-4">How We Use Your Information</h2>
                <div className="text-gray-700 mb-8 ml-4">
                    <p className='mb-2'>We utilize the collected information for several purposes:</p>
                    <p><span className='font-semibold text-gray-900'>- To Provide Our Services: </span>{" Your personal information helps us create and manage your account and provide customer support."}</p>
                    <p><span className='font-semibold text-gray-900'>- To Improve Our Services:</span>{" Usage data allows us to understand user behavior and improve our offerings based on feedback and analytics."}</p>
                    <p><span className='font-semibold text-gray-900'>- To Communicate with You:</span>{" We may send you updates about your account, promotional materials, or other relevant communications."}</p>
                    <p><span className='font-semibold text-gray-900'>- For Legal Compliance:</span>{" We may process your data to comply with applicable laws or respond to legal requests."}</p>
                </div>

                <h2 className="text-2xl font-semibold mb-4">Data Sharing and Disclosure</h2>
                <div className="text-gray-700 mb-8 ml-4">
                    <p className='font-normal mb-2'>We do not sell or rent your personal information to third parties. However, we may share your data in the following circumstances:</p>
                    <p><span className='font-semibold text-gray-900'>- With Service Providers:</span>{" We may employ third-party companies to assist in providing our services (e.g., payment processors), who will have access only to the necessary data required for their functions."}</p>
                    <p><span className='font-semibold text-gray-900'>- For Legal Reasons:</span>{" If required by law or in response to valid requests by public authorities (e.g., a court or government agency)."}</p>
                    <p><span className='font-semibold text-gray-900'>- Business Transfers:</span>{" In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred as part of that transaction."}</p>
                </div>
                
                <h2 className="text-2xl font-semibold mb-4">Your Rights</h2>
                <div className="text-gray-700 mb-8 ml-4">
                    <p className='mb-2'>You have certain rights regarding your personal data:</p>
                    <p><span className='font-semibold text-gray-900'>- Access:</span>{" You can request access to the personal information we hold about you."}</p>
                    <p><span className='font-semibold text-gray-900'>- Correction:</span>{" You can request corrections if any of your personal information is inaccurate or incomplete."}</p>
                    <p><span className='font-semibold text-gray-900'>- Deletion:</span>{" You can request that we delete your personal data under certain conditions."}</p>
                    <p><span className='font-semibold text-gray-900'>- Opt-Out:</span>{" You can opt-out of receiving marketing communications at any time by following the unsubscribe instructions included in those communications."}</p>

                </div>

                <h2 className="text-2xl font-semibold mb-4">Changes to Our Privacy Policy</h2>
                <div className="text-gray-700 mb-8 ml-4">
                    We reserve the right to update this Privacy Policy at any time. Any changes will be posted on this page with an updated effective date. We encourage users to review this policy periodically for any updates.
                </div>

                <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
                <div className="text-gray-700 ml-4">
                    If you have any questions about this Privacy Policy or our practices regarding personal data protection, please contact us at <span className='text-blue-500 font-semibold'>support@surfheim.com</span>.
                </div>
            </div>

            <Footer />
        </div>
    )
};

export default Privacy;
