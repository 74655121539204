import React, { useState, useEffect, useRef }from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { IoQrCodeOutline } from "react-icons/io5";
import { QRCodeSVG } from 'qrcode.react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const LoadingSpinner = () => (
  <div className="flex items-center justify-center h-screen">
    <div className="animate-spin rounded-full h-10 w-10 border-4 border-t-accentHover border-gray-300"></div>
  </div>
);


const Account = () => {
  const [token, setToken] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [freeLoading, setFreeLoading] = useState(false);
  const [showQrCode, setShowQrCode] = useState(null);
  const qrCodeRef = useRef(null);
  const navigate = useNavigate();


  const copyToClipboard = (link) => {
    navigator.clipboard.writeText(link)
    .then(() => {
      toast.info('Link copied to clipboard!', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
      });
      })
      .catch(error => {
        toast.warning('Something went wrong!', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "colored",
        });
      });
    };


  const toggleQrCode = (index) => {
    setShowQrCode(showQrCode === index ? null : index);
  };


  const handleOverlayClick = (e) => {
    if (qrCodeRef.current && !qrCodeRef.current.contains(e.target)) {
      setShowQrCode(null);
    }
  };
  
  
  const handleSubmitPlan = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem('accessToken');

    if (token) {
      axios.get('https://api.surfheim.com/authentication/', {
          headers: { 'x-access-token': token }
      }).then(response => {
          if (response.status === 200) {
              navigate('/Order/')
          } else {
              navigate('/Sign-in/')
          }}
      ).catch(error => {
          setLoading(false);
          navigate('/Sign-in/')
      });
    } else {
      navigate('/Sign-in/')
    }
  }

  const handleSubmitFree = async (e) => {
    e.preventDefault();
    setFreeLoading(true);

    const token = localStorage.getItem('accessToken');

    if (token) {
      axios.post('https://api.surfheim.com/free-plan/', {
          headers: { 'x-access-token': token }
      }).then(response => {
          if (response.status === 200) {
            toast.success('Free subscription is created.', {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              theme: "colored",
            });

            window.location.reload();
          }}
      ).catch(error => {
          if (error.response.data === 'Free plan is used!'){
            toast.error('Not available! Free plan is already used!', {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              theme: "colored",
            });

            setFreeLoading(false)

          } else {
            toast.error('An Error Happened!', {
              position: "top-center",
              icon: 'error',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });

            window.location.reload();
          }
            
      });
    } else {
      toast.error('An error happened! Please try later.', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
      });

      setFreeLoading(false)
    }
  }
  

  useEffect(() => {
    const storedToken = localStorage.getItem('accessToken');
    if (storedToken) {
      setToken(storedToken);
    } else {
      window.location.href = '/sign-in/';
    }

  }, []);


  useEffect(() => {
    if (token) {
      setLoading(true);
      axios.defaults.headers.common['x-access-token'] = token;
      axios.get('https://api.surfheim.com/profile/')
       .then(response => {
          if (response.status === 200) {
            setUserData(response.data.userdata);
          }
        })
       .catch(error => {
          toast.warn('Authentication Error! Please login.', {
            position: 'top-center',
            icon: 'check',
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        });

        setTimeout(() => {
            window.location.href = '/sign-in/';
        }, 3000);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [token]);


  if (loading) {
    return <LoadingSpinner />;
  }


  return (
    <section className=''>
      
      <div className='md:container mx-auto'>
        <div className='flex flex-col px-8 sm:px-16 py-2 justify-evenly rounded-2xl my-4 mx-4 shadow-lg border border-dark/25'>

          <div>
            <p className='text-dark font-semibold'>User info:</p>
            <hr />
          </div>

          <div className='flex flex-col sm:flex-row justify-start sm:justify-between'>
            <div className='flex flex-row sm:flex-col justify-center'>
              <p className='text-dark/55'>Username:</p>
              <p className='text-dark/85 pl-1'>{userData?.username || ''}</p>
            </div>

            <div className='flex flex-row sm:flex-col justify-center'>
              <p className='text-dark/55'>Email:</p>
              <p className='text-dark/85 pl-1'>{userData?.email || ''}</p>
              <hr className='md:hidden'/>
            </div>

            <div className='flex flex-row sm:flex-col justify-center'>
              <p className='text-dark/55'>Balance: </p>
              <p className='text-dark/85 pl-1'> ${userData?.balance || '0'}</p>
              <hr className='md:hidden'/>
            </div>

            <div className='flex flex-row sm:flex-col justify-center'>
              <p className='text-dark/55'>service:</p>
              <p className='text-dark/85 pl-1'>
                {userData?.orders && userData.orders.length > 0 ? userData.orders[0].service_name : 'Null'}
              </p>
            </div>
          </div>
        </div >

        <div className='flex flex-col px-8 sm:px-16 py-2 justify-evenly rounded-2xl my-4 mx-4 shadow-lg border border-dark/25'>
          <div>
            <p className='text-dark font-semibold'>Subscription:</p>
            <hr />
          </div>

          {userData?.subscriptions && userData.subscriptions.length > 0 ? (
            userData.subscriptions.map((subscription, index) => (
            <div key={index} className='grid grid-cols-2 md:grid-cols-3 lg:flex lg:flex-row md:justify-between py-2'>
              <div className='flex flex-col items-center lg:items-start justify-center'>
                <p className='text-dark/55'>Plan name</p>
                <p className='text-dark/85'>{subscription.plan_name || 'Null'}</p>
              </div>

              <div className='flex flex-col items-center justify-center'>
                <p className='text-dark/55'>Status</p>
                <p className='text-dark/85'>
                  {subscription.status === 'active' ? (
                    <span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'green', marginRight: '5px' }}></span>
                  ) : subscription.status === 'limited' ? (
                    <span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'orange', marginRight: '5px' }}></span>
                  ) : (
                    <span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'red', marginRight: '5px' }}></span>
                  )}
                  {subscription.status || 'Null'}
                </p>
              </div>

              <div className='flex flex-col items-center justify-center'>
                <p className='text-dark/55'>Data usage:</p>
                <p className='text-dark/85'>
                  {subscription.data_usage !== undefined && subscription.data_usage !== null 
                    ? `${subscription.data_usage} GB` : 'Null'}
                </p>
              </div>
            
              <div className='flex flex-col items-center justify-center'>
                <p className='text-dark/55'>Remaining Time:</p>
                <p className='text-dark/85'>
                  {subscription.remaining !== undefined && subscription.remaining !== null 
                    ? `${subscription.remaining} day(s)` : 'Null'}
                </p>
              </div>
            
              <div className='flex flex-col items-center justify-center'>
                <p className='text-dark/55'>Subscription Link:</p>
                <p className='text-dark/85 cursor-pointer' onClick={() => copyToClipboard(subscription.link)}>Click to Copy</p>
              </div>

              <div className='flex flex-col items-center justify-center'>
                <p className='text-dark/55'>QR code:</p>
                <p className='text-dark/85 cursor-pointer' onClick={() => toggleQrCode(index)}><IoQrCodeOutline /></p>
              </div>
            
            </div>))
            ):(
              <p className='text-dark/85 pt-4'>No subscription found.</p>
            )}
          
          <hr className='my-4'/>

          <div className='flex flex-row justify-evenly items-center gap-y-2'>
            <button 
              disabled={loading}
              onClick={handleSubmitPlan}
              className='bg-accent rounded-md p-1.5 px-4 text-sm sm:text-md mx-auto text-white hover:bg-accentHover font-secondary font-semibold'>
              {loading ? 'Loading...' : 'Order a Plan'}
            </button>
            
            <button 
              onClick={handleSubmitFree}
              disabled={freeLoading}
              className='bg-accent rounded-md p-1.5 px-4 text-sm sm:text-md mx-auto text-white hover:bg-accentHover font-secondary font-semibold'>
              {freeLoading ? 'Loading...' : 'Get Free Plan'}
            </button>
          </div>
        </div>

  
        <div className='flex flex-col px-1 sm:px-16 py-2 justify-evenly rounded-2xl my-4 mx-4 shadow-lg border border-dark/25'>
          
          <div>
            <p className='text-dark font-semibold mb-2'>Orders:</p>
          </div>

          {userData?.orders && userData.orders.length > 0 ? (
            <div className="overflow-x-auto">
              <table className='min-w-full w-full border-collapse '>
                <thead>
                  <tr className='bg-gray-100'>
                    <th className='px-2 sm:px-4 py-2 text-left text-sm sm:text-base'>Plan Name</th>
                    <th className='px-2 sm:px-4 py-2 text-left text-sm sm:text-base'>Start Date</th>
                    <th className='px-2 sm:px-4 py-2 text-left text-sm sm:text-base'>End Date</th>
                    <th className='px-2 sm:px-4 py-2 text-left text-sm sm:text-base'>Paid Price</th>
                    <th className='px-2 sm:px-4 py-2 text-left text-sm sm:text-base'>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.orders.map((order, index) => (
                    <tr key={index} className='hover:bg-gray-50'>
                      <td className='px-2 sm:px-4 py-2 text-sm sm:text-base'>{order.service_name || 'Null'}</td>
                      <td className='px-2 sm:px-4 py-2 text-sm sm:text-base'>{order.start_date || 'Null'}</td>
                      <td className='px-2 sm:px-4 py-2 text-sm sm:text-base'>{order.end_date || 'Null'}</td>
                      <td className='px-2 sm:px-4 py-2 text-sm sm:text-base'>{order.paid_price || 'Null'}</td>
                      <td className='px-2 sm:px-4 py-2 text-sm sm:text-base'>{order.service_status || 'Null'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            ) : (
            <p className='text-dark/85 pt-4'>No Order found.</p>
            )}
          </div>

        <div className='flex flex-col px-1 sm:px-16 py-2 justify-evenly rounded-2xl my-4 mx-4 shadow-lg border border-dark/25'>
          <div>
            <p className='text-dark font-semibold mb-2'>Logs:</p>
          </div>

          {userData?.logs && userData.logs.length > 0 ? (
            <div className="overflow-x-auto">
              <table className='min-w-full w-full sm:w-auto'>
                <thead>
                  <tr className='bg-gray-100'>
                    <th className='px-2 sm:px-4 py-2 text-left text-sm sm:text-base'>IP</th>
                    <th className='px-2 sm:px-4 py-2 text-left text-sm sm:text-base'>Location</th>
                    <th className='px-2 sm:px-4 py-2 text-left text-sm sm:text-base'>Device</th>
                    <th className='px-2 sm:px-4 py-2 text-left text-sm sm:text-base'>Time (UTC)</th>
                  </tr>
                </thead>
                <tbody>
                  {userData.logs.map((log, index) => (
                    <tr key={index} className='hover:bg-gray-50'>
                      <td className='px-2 sm:px-4 py-2 text-sm sm:text-base'>{log.ip || 'Null'}</td>
                      <td className='px-2 sm:px-4 py-2 text-sm sm:text-base'>
                        {log.location && log.location.city && log.location.country
                          ? `${log.location.city} - ${log.location.country}`
                          : log.location?.city || log.location?.country || 'Null'}
                      </td>
                      <td className='px-2 sm:px-4 py-2 text-sm sm:text-base'>{log.device || 'Null'}</td>
                      <td className='px-2 sm:px-4 py-2 text-sm sm:text-base'>{log.time || 'Null'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            ) : (
              <p className='text-dark/85'>No Logs found.</p>
            )}
        </div>

        <div className='flex flex-col py-2 my-4 mx-4 sm:mx-16'>
          <p className='text-dark font-semibold'>Privacy and settings</p>
          <a href='/Change-Password/'><p className='text-dark/55 ml-4'>Change password</p></a>
          {/* <a href='/'><p className='text-dark/55 ml-4'>Change username</p></a>
          <a href='/'><p className='text-dark/55 ml-4'>Delete account</p></a> */}
        </div>

          <div className='flex flex-col py-2 my-4 mx-4 sm:mx-16'>
            <p className='text-dark font-semibold'>Have a problem?</p>
            <a href='/Ticket/'><p className='text-dark/55 ml-4'>Send ticket</p></a>
            
          </div>
      </div>

      {showQrCode !== null && (
        <div 
          className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50' 
          onClick={handleOverlayClick}>
          <div 
            ref={qrCodeRef} 
            className='bg-white p-4 rounded shadow-lg' 
            onClick={(e) => e.stopPropagation()}
          >
            <QRCodeSVG value={userData.subscriptions[showQrCode].link} size={256} /> 
          </div>
        </div>
      )}

        <ToastContainer />
        
    </section>
  )
};

export default Account;