import React, { useState, useEffect } from 'react';
import logo from '../../assets/img/surfheim-logo.png';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgetPass = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState('');
  const [turnstileCompleted, setTurnstileCompleted] = useState(false);

  const siteKey = '0x4AAAAAAAh7yiqQr45zLiE8';
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    const turnstileScript = document.createElement('script');
    turnstileScript.src = `https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback`;
    document.body.appendChild(turnstileScript);
    
    window.onloadTurnstileCallback = () => {
      window.turnstile.render('#turnstile-container', {
        sitekey: siteKey,
        callback: turnstileCallback,
      });
    };

    const turnstileCallback = (token) => {
      setTurnstileToken(token);
      setTurnstileCompleted(true);
    };

    return () => {
      document.body.removeChild(turnstileScript);
    };
  }, [siteKey]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading || !turnstileCompleted) return; 

    setLoading(true);

    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
      });

      setTurnstileToken(null);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post('https://api.surfheim.com/Forget-password/', {
        "email": email,
        "turnstileToken": turnstileToken
      }, { headers: { "Content-Type": "application/json" } });

      if (response.status === 200) {
        toast.success('Reset link is sent to your email', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "colored",
        });

        setTurnstileToken(null);
        setTurnstileCompleted(false);
        setTimeout(() => {
          window.location.href = '/';
        }, 3000);
      }

    } catch (error) {
      toast.error(error.response?.data?.message || 'An unexpected error occurred', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
      });

      setTurnstileToken(null);
      setLoading(false);
    }
  };

  return (
    <div className="h-screen w-full flex justify-center items-center flex-col pb-36 md:pb-24 bg-dark">
      <div className="max-w-[320px] min-h-[440px] sm:max-w-[420px] sm:min-h-[540px] bg-active rounded-xl py-4 justify-center items-center px-8 sm:px-12">
        <img src={logo} alt="Logo" className="w-48 sm:w-64 mx-auto mb-2 py-4 sm:py-6" />
        <h1 className="font-semibold text-2xl sm:text-4xl text-white tracking-tight my-4 sm:pb-4 text-center">Forget Password</h1>
        <p className="text-md text-center text-white mb-4 font-secondary">Please enter your email Address</p>
                
        <input
          type="email"
          value={email}
          onChange={handleEmailChange}
          className="border rounded-lg w-full align-bottom p-2 sm:p-3 border-active font-secondary"
          placeholder="Enter Email Address"
        />

        <button
          onClick={handleSubmit}
          disabled={loading || !turnstileCompleted}
          className="rounded-lg cursor-pointer bg-blue-500 border-blue-500 w-full mt-4 text-white py-2 sm:py-2.5 font-secondary font-semibold">
          {loading ? 'Loading...' : 'Reset Password'}
        </button>
        
        <div id="turnstile-container" className="mt-3 items-center"></div>

        <p className="flex flex-row text-md text-blue-400 mt-10 text-center font-secondary justify-center">
            <a href="/Sign-in/">Sign in</a>
            <p className='text-white mx-1'>-</p>
            <a href="/Sign-up/">Sign up</a>
        </p>
                
      </div>

      <div className="flex flex-col md:flex-row text-white mb-2 text-[14px] font-secondary"
        style={{position: 'fixed', bottom: '2px', display: 'flex', justifyContent: 'center', alignItems: 'center',}}>

        <div className="flex flex-row gap-x-4">
          <div>
            <a href="/">Home</a>
          </div>
        
          <div>
            <a href="/Terms-of-Service/">Term of service</a>
          </div>
          <div>
            <a href="/Privacy-Policy/">Privacy Policy</a>
          </div>
        </div>

        <div className="mx-0 md:mx-16 lg:mx-36 xl:mx-44">
          support@surfheim.com
        </div>
                
        <div className="mr-0 md:mr-3">
          Copyright &copy; {new Date().getFullYear()} - All Rights Reserved
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default ForgetPass;