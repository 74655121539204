import React from 'react';
import img3 from '../../../assets/img/surfheim-feature3-img.png';


const Feature3 = () => {
  const schemaMarkup = {
      "@context": "https://schema.org",
      "@type": "ImageObject",
      "url": img3,
      "caption": "Surfheim VPN - 24/7 Support. Whether you need help setting up your VPN or troubleshooting connectivity problems, our experts are just a message away.",
      "contentUrl": img3,
      "width": 500,
      "height": 410 
    };

  return (
    <section className='section'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row lg:items-start lg:gap-x-[30px]'>

          <div className='flex-1' >
            <h1 className='pretitle'>24/7 Support</h1>
            <h2 className='title'>Always available for your needs</h2>
            <p className='lead text-justify'>Our dedicated support team is available 24/7 to assist you with any inquiries or technical issues you may encounter. Whether you need help setting up your VPN or troubleshooting connectivity problems, our experts are just a message away. We believe in providing exceptional customer service to enhance your experience with our VPN.</p>
            <a href='/Features/'>
              <button className='btn-link flex items-center gap-x-3 hover:gap-x-5 transition-all'>
                Learn more  
              </button>
            </a>

          </div>
          <div className='flex-1' >
            <img src={img3} alt='Surfheim VPN - 24/7 Support. Whether you need help setting up your VPN or troubleshooting connectivity problems, our experts are just a message away.'/>
          </div>

        </div>
      </div>

      <script type="application/ld+json">
        {JSON.stringify(schemaMarkup)}
      </script>

    </section>
  )
};

export default Feature3;