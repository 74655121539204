import React, { useState} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import image from '../../../assets/img/surfheim-image.png';


const Hero = () => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ImageObject",
    "url": image,
    "caption": "Surfheim VPN - Protect your data and information with Surfheim. Secure connection, data encryption and no-logs policy VPN service. Best, fast, very affordable and highly recommended VPN service",
    "contentUrl": image,
    "width": 500,
    "height": 495 
  };

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem('accessToken');

    if (token) {
      axios.get('https://api.surfheim.com/authentication/', {
          headers: { 'x-access-token': token }
      }).then(response => {
          if (response.status === 200) {
              navigate('/Order/')
          } else {
              navigate('/Sign-in/')
          }}
      ).catch(error => {
          setLoading(false);
          navigate('/Sign-in/')
      });
    } else {
      navigate('/Sign-in/')
    }
  }
  
  return (
    <section className=''>
      <div className='container mx-auto flex justify-center items-center'>
        <div className='flex flex-col lg:gap-x-[30px] gap-y-6 lg:gap-y-0 lg:flex-row md:flex-row items-center justify-center text-center lg:text-left'>
          <div className='flex-1'>
            <h1 className='title mb-2 lg:mb-5 mt-4 md:mt-0'> Secure Your Browsing Experience</h1>
            <h3 className='lead mb-5 lg:mb-10 font-secondary'> Protect your data, browse anonymously and bypass restrictions.</h3>
            <div className='flex items-center max-w-sm lg:max-w-full mx-auto lg:mx-0 gap-x-2 lg:gap-x-6 font-secondary'>
              <button 
                onClick={handleSubmit}
                disabled={loading} 
                className='btn btn-sm lg:btn-md btn-accent flex justify-center items-center lg:gap-x-4'>
                  
                Get Surfheim
              </button>
              <span className='text-dark/65'>Android, iOS and Desktop</span>
            </div>
          </div>
          <div className='flex-1'>
            <img src={image}  alt='Surfheim VPN | Protect your data and information with Surfheim. Secure connection, data encryption and no-logs policy VPN service. Best, fast, very affordable and highly recommended VPN service'/>
          </div>
        </div>

      </div>

      <script type="application/ld+json">
        {JSON.stringify(schemaMarkup)}
      </script>
      
    </section>
  )
};

export default Hero;