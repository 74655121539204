import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Fund from './Components/Fund';


const TopUp = () => {
  return (
    <div className='overflow-hidden'>
        <Header />
        <Fund />
        <Footer />
    </div>
  );
};

export default TopUp;