import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/img/surfheim-logo.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Verify = () => {
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendAvailable, setResendAvailable] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const location = useLocation();
  const navigate = useNavigate();
  const [turnstileToken, setTurnstileToken] = useState('');
  
  const siteKey = '0x4AAAAAAAh7yiqQr45zLiE8';
  const email = location.state.email;

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);
    return () => clearInterval(countdownInterval);
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      setResendAvailable(true);
    }
  }, [countdown]);
  
  
  useEffect(() => {
      const turnstileScript = document.createElement('script');
      turnstileScript.src = `https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback`;
      document.body.appendChild(turnstileScript);
      window.onloadTurnstileCallback = () => {
          window.turnstile.render('#turnstile-container', {
              sitekey: siteKey,
              callback: turnstileCallback,
          });
      };

      const turnstileCallback = (token) => {
          setTurnstileToken(token);
      };

      return () => {
          document.body.removeChild(turnstileScript);
      };
  }, [siteKey]);


  const handleVerify = async (e) => {
    e.preventDefault();
    if (loading || !turnstileToken) return;

    setLoading(true);

    try {
      const response = await axios.post('https://api.surfheim.com/verify-email/', { 
        "email": email,
        "verification_code": code,
        'turnstileToken': turnstileToken,
      }, { headers: {"Content-Type": "application/json"}});
      
      if (response.status === 200) {
        const token = response.data.token;
        localStorage.setItem('accessTokenVal', token);
        toast.success('Successfully verified.', {
          position: "top-center",
          icon: 'check',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "colored",
        });

        setTurnstileToken(null);
        setTimeout(() => {
          navigate('/Complete-registration/', {state: { email }});
        }, 2000);
      } 
    } catch (error) {
      toast.error(error.response?.data['message'] || 'Verification failed. Please try again.', {
        position: "top-center",
        icon: 'error',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
      });
      setTurnstileToken(null);
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    try {
      const response = await axios.post('https://api.surfheim.com/register-email/', {
        "email": email,
        'turnstileToken': turnstileToken,
      }, { headers: {"Content-Type": "application/json"}});

      if (response && response.status === 200) {
        toast.success('New code is sent to your email!', {
          position: "top-center",
          icon: 'check',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          theme: "colored",
        });
  
        setResendAvailable(false);
        setCountdown(60);
      }
    } catch (error) {
      toast.error(error.response?.data['message'] || 'Failed to resend code. Please try again.', {
        position: "top-center",
        icon: 'error',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
      });
    }
  };

  return (
    <div className="h-screen w-full flex justify-center items-center flex-col pb-36 md:pb-24 bg-dark">
      <div className="max-w-[320px] min-h-[440px] sm:max-w-[420px] sm:min-h-[540px] bg-active rounded-xl py-4 justify-center items-center px-8 sm:px-12">
        <img src={logo} alt="Logo" className="w-48 sm:w-64 mx-auto mb-2 py-4 sm:py-6" />
        <h1 className="font-bold text-xl sm:text-3xl text-white tracking-tight my-2 pb-4 text-center">Verify email</h1>
        
        <p className="text-md text-center text-white mb-2 font-secondary">We sent a code to your email:</p>
        <p className="text-lg font-semibold font-secondary text-center text-white mb-2">{email}</p>
        <div className="flex flex-col items-center mb-4">

          <form onSubmit={handleVerify}>
            <input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Enter code"
              className="border rounded-lg w-full align-bottom p-2 sm:p-3 border-active font-secondary"
            />
            
            <button
              type="submit"
              className="rounded-lg cursor-pointer bg-blue-500 border-blue-500 w-full mt-4 text-white py-2 sm:py-2.5 font-secondary font-semibold"
            >
              {loading ? 'Loading' : 'Verify'}
            </button>
          </form>
          <div>
            {resendAvailable ? (
              <p className="text-white text-sm mt-4 sm:mt-8 font-secondary">
                Didn't receive the code?{' '}
                <button
                  onClick={handleResendCode}
                  className="text-blue-500 hover:text-blue-700">
                  Resend code
                </button>
              </p>
            ) : (
              <p className="text-white text-sm mt-4 sm:mt-8 font-secondary flex ">
                Resend will be available in <span className='text-blue-500 px-1'>{countdown}</span> seconds
              </p>
            )}
          </div>
          <div className='text-sm font-secondary mt-2'>
            <button
              onClick={handleBack}
              className="text-blue-500 hover:text-blue-700">
                Back
            </button>
          </div>

          <div id="turnstile-container" className="mt-3 items-center"></div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row text-white mb-2 text-[14px] font-secondary"
        style={{ position: 'fixed', bottom: '2px', display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
          
        <div className="flex flex-row gap-x-4">
          <div>
            <a href="/">Home</a>
          </div>
          <div>
            <a href="/Terms-of-Service/">Term of service</a>
          </div>
          <div>
            <a href="/Privacy-Policy/">Privacy Policy</a>
          </div>
        </div>

        <div className="mx-0 md:mx-16 lg:mx-36 xl:mx-44">
            support@surfheim.com
        </div>

        <div className="mr-0 md:mr-3">
            Copyright &copy; { new Date().getFullYear()} - All Rights Reserved
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default Verify;