import React, { useState} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import demoImg from '../../../assets/img/surfheim-dataSecurity.png';



const Demo = () => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ImageObject",
    "url": demoImg,
    "caption": "Surfheim VPN Free trial - Secure your connection and data encryption with no-logs policy",
    "contentUrl": demoImg,
    "width": 800,
    "height": 800 
  };

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem('accessToken');

    if (token) {
      axios.get('https://api.surfheim.com/authentication/', {
          headers: { 'x-access-token': token }
      }).then(response => {
          if (response.status === 200) {
              navigate('/Profile/')
          } else {
              navigate('/Sign-in/')
          }}
      ).catch(error => {
          setLoading(false);
          navigate('/Sign-in/')
      });
    } else {
      navigate('/Sign-in/')
    }
  }
  
  return (
    <section className='section bg-gradient-to-br from-active via-accent to-active/35'>
      <div className='max-w-[1340px] mx-auto px-[25px]'>
        <div className='flex flex-col lg:flex-row lg:items-start items-center lg:gap-x-[30px]'>

          <div className='flex justify-between'>
            <img className='flex h-80' src={demoImg} alt='Surfheim VPN Free trial - Secure your connection and data encryption with no-logs policy'/>

          </div>

          <div className='max-w-[640px] mx-auto text-center my-auto '>
            <h2 className='title text-white mb-6 ' >Your Privacy, Your Power</h2>
            <h2 className='h2 text-white mb-8 ' >Start Your Free Trial Today!</h2>
            <h3 className='text-xl lg:text-2xl text-white ' >Try Surfheim Vmess/Vless-based VPN service free for 7 days and enjoy full access to all features!</h3>
            <button 
              onClick={handleSubmit}
              disabled={loading}
              className='btn btn-md btn-white mt-[36px] lg:text-[18px] gap-x-[10px] mx-auto font-semibold shadow-xl' >
              Try for Free
            </button>
          </div>
        </div>
      </div>

      <script type="application/ld+json">
        {JSON.stringify(schemaMarkup)}
      </script>

    </section>
  )
};

export default Demo;