import React, { useState, useEffect } from 'react';
import { RiShieldCheckFill } from "react-icons/ri";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OrderSummary = ({ plan }) => {
    const [token, setToken] = useState(null);
    const [balance, setBalance] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isSufficientBalance, setIsSufficientBalance] = useState(true);

    useEffect(() => {
        const storedToken = localStorage.getItem('accessToken');
        if (storedToken) {
            setToken(storedToken);
        } else {
            window.location.href = '/sign-in/';
        }
    }, []);

    useEffect(() => {
        if (token) {
            axios.defaults.headers.common['x-access-token'] = token;
            axios.get('https://api.surfheim.com/Get-balance/')
                .then(response => {
                    if (response.status === 200) {
                        setBalance(response.data.balance);
                    }
                })
                .catch(error => {
                    toast.warn('Authentication Error! Please login.', {
                        position: 'top-center',
                        icon: 'check',
                        autoClose: 4000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });

                    setTimeout(() => {
                        window.location.href = '/sign-in/';
                    }, 3000);
                });
        }
    }, [token]);

    const handleCompletePurchase = () => {
        if (balance < plan.totalPrice) {
            toast.error(`Insufficient balance! You need $${(plan.totalPrice - balance).toFixed(2)} more.`, {
                position: 'top-center',
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
        } else {
            setShowConfirmation(true);
        }
    };

    const confirmPurchase = () => {
        // Logic to handle the purchase can be added here
        toast.success('Purchase completed successfully!', {
            position: 'top-center',
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        });
        setShowConfirmation(false);
    };

    const cancelPurchase = () => {
        setShowConfirmation(false);
    };

    return (
        <section className="my-10 ">
            <div className='flex flex-row items-center font-semibold font-secondary mt-8'>
                <div className='text-white bg-accentHover w-8 h-8 mx-4 mb-4 flex justify-center items-center rounded-full'>2</div>
                <div className='text-xl text-accentHover pb-4'>Review selected plan and Complete payment:</div>
            </div>

            <div className='flex flex-col mx-auto lg:flex-row items-start justify-center mt-4 gap-x-6 md:gap-x-12 lg:gap-x-20 gap-y-8 md:gap-y-0'>
                <div className='flex flex-col'>
                    <div className='bg-white border-[2px] border-gray-200 rounded-xl w-[340px] sm:w-[360px] md:w-[400px] lg:w-[480px] pb-4'>
                        <h2 className="text-[24px] text-dark font-semibold px-6 py-4 font-secondary">Order Summary</h2>
                        <div className='flex flex-row justify-between px-6'>
                            <p className="text-[20px] text-dark/75 font-semibold">{plan.title} Plan</p>
                            <div className='flex flex-row gap-x-3'>
                                <p className="text-[20px] line-through text-dark/45 font-semibold">{plan.initPrice}</p>
                                <p className="text-[20px] text-dark/75 font-semibold">{plan.price} / month</p>
                            </div>
                        </div>

                        <hr className='mx-6 my-4'/>

                        <div className='flex flex-row justify-between px-6'>
                            <h2 className="text-[22px] font-semibold text-dark">Total bill:</h2>
                            <div className='flex flex-row gap-x-3'>
                                <p className="text-[20px] line-through text-red-600 font-medium">{plan.totalInitPrice}</p>
                                <p className="text-[20px] text-green-600 font-bold">${plan.totalPrice}</p>
                            </div>
                        </div>

                        <div className='mx-6 my-4'>
                            <p className='text-sm font-semibold'>Note:</p>
                            <p className="text-sm font-medium text-dark/85 align-middle">{plan.description}</p>
                        </div>

                        <div className='mx-6 mt-6 flex flex-row align-middle bg-accent/15 p-2 rounded-lg'>
                            <RiShieldCheckFill className='text-green-600 text-[36px] pb-3'/>
                            <p className="text-sm font-medium text-dark/65 ml-2 align-baseline"> To ensure your complete satisfaction, we offer a full refund within {plan.moneyBack}, if you are not entirely happy with our service.</p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col bg-white border-[2px] border-gray-200 rounded-lg w-[340px] sm:w-[360px] md:w-[400px] lg:w-[480px] lg:mt-0 mt-4 md:mb-4 pb-4">
                    <span className='text-[24px] text-dark font-secondary font-semibold px-6 py-4'>Completing the Order</span>

                    <div className='flex flex-row justify-between items-center px-6'>
                        <span className='text-[16px] sm:text-[20px] text-dark/75 font-semibold'>Account Info</span>
                        <div className='flex flex-row gap-x-1'> 
                            <MdOutlineAccountBalanceWallet className='text-accent text-[32px]'/>
                        </div>
                    </div>

                    <hr className='mx-6 my-4'/>

                    <div className='flex flex-row justify-between items-center px-6'>
                        <span className='text-[16px] sm:text-[20px] text-dark/85 font-semibold'>Balance</span>
                        <span className='text-[16px] sm:text-[20px] text-dark/85 font-semibold'>${balance}</span>
                    </div>

                    <div className='flex flex-row justify-between items-center px-6'>
                        <span className={`text-sm sm:text-md font-semibold ${balance >= plan.totalPrice ? 'text-green-600' : 'text-red-600'}`}>
                            {balance >= plan.totalPrice ? 'Sufficient Balance' : `Insufficient Balance - Need $${(plan.totalPrice - balance).toFixed(2)} more`}
                        </span>
                    </div>

                    <div className='px-6'>
                        {balance < plan.totalPrice && (
                            <a href="/Top-up/" className="text-accent text-sm underline">Click here to add funds</a>
                        )}
                    </div>
                    

                    <div className='flex flex-col mx-6 gap-y-2 pt-4'>
                        <button onClick={handleCompletePurchase} className="w-full text-md rounded-md cursor-pointer bg-accent text-white py-1 mx-auto px-4 lg:mx-22 my-2 font-secondary font-semibold">
                            Complete Purchase
                        </button>
                        
                    </div>
                </div>
            </div>

            {showConfirmation && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h2 className="text-lg font-semibold">Confirm Purchase</h2>
                        <p>Are you sure you want to complete the purchase?</p>
                        <div className="flex justify-end mt-4">
                            <button onClick={cancelPurchase} className="mr-2 text-gray-500">Cancel</button>
                            <button onClick={confirmPurchase} className="bg-accent text-white px-4 py-2 rounded">Confirm</button>
                        </div>
                    </div>
                </div>
            )}

            <ToastContainer />
        </section>
    );
};

export default OrderSummary;