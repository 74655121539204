import React from 'react';
import { BsInstagram } from 'react-icons/bs';
import { BsTwitter } from 'react-icons/bs';
import { BsLinkedin } from 'react-icons/bs';
import { BsTelegram } from 'react-icons/bs';

const Footer = () => {
  return (
    <footer className="bg-active py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-center mb-12 font-secondary">
          <div className="w-full md:w-1/3 xl:w-1/5 p-6">
            <h2 className="text-lg font-bold text-white">Legal</h2>
            <ul>
              <li><a href="/Terms-of-service/" className="text-sm font-medium text-gray-100 hover:text-gray-400">Terms of Service</a></li>
              <li><a href="/Privacy-policy/" className="text-sm font-medium text-gray-100 hover:text-gray-400">Privacy Policy</a></li>
              <li><a href="/Cookies-and-preferences/" className="text-sm font-medium text-gray-100 hover:text-gray-400">Cookies Preferences</a></li>
              <li><a href="/Refund-policy/" className="text-sm font-medium text-gray-100 hover:text-gray-400">Refund Policy</a></li>
            </ul>
          </div>

          <div className="w-full md:w-1/3 xl:w-1/5 p-6">
            <h2 className="text-lg font-bold text-white">Links</h2>
            <ul>
              <li><a href="/" className="text-sm font-medium text-gray-100 hover:text-gray-400">Home</a></li>
              <li><a href="/About-us/" className="text-sm font-medium text-gray-100 hover:text-gray-400">About us</a></li>
              <li><a href="/Features/" className="text-sm font-medium text-gray-100 hover:text-gray-400">Features</a></li>
              <li><a href="/Pricing/" className="text-sm font-medium text-gray-100 hover:text-gray-400">Pricing</a></li>
              <li><a href="https://blog.surfheim.com" className="text-sm font-medium text-gray-100 hover:text-gray-400">Blog</a></li>
              
            </ul>
          </div>

          <div className="w-full md:w-1/3 xl:w-1/5 p-6">
            <h2 className="text-lg font-bold text-white">Tools</h2>
            <ul>
              <li><a href="https://browserleaks.com/webrtc" className="text-sm font-medium text-gray-100 hover:text-gray-400">WebRTC Leak</a></li>
              <li><a href="https://dnsleaktest.com/" className="text-sm font-medium text-gray-100 hover:text-gray-400">DNS Leak</a></li>
              <li><a href="https://whatismyipaddress.com/" className="text-sm font-medium text-gray-100 hover:text-gray-400">IP Info</a></li>
              <li><a href="https://speedtest.net.uk/" className="text-sm font-medium text-gray-100 hover:text-gray-400">Speed Test</a></li>
            </ul>
            
          </div>

          <div className="w-full md:w-1/3 xl:w-1/5 p-6">
            <h2 className="text-lg font-bold text-white">Social Media</h2>
            <ul className='flex flex-col gap-y-2.5 pt-2'>
              <li><a href="https://www.instagram.com/surfheim?igsh=ZDh3ZTkzcGxuMG91" className="text-2xl font-medium text-gray-100 hover:text-gray-400"><BsInstagram /></a></li>
              <li><a href="https://t.me/surfheim" className="text-2xl font-medium text-gray-100 hover:text-gray-400"><BsTelegram /></a></li>
              <li><a href="https://x.com/surheim" className="text-2xl font-medium text-gray-100 hover:text-gray-400"><BsTwitter /></a></li>
              <li><a href="https://linkedin.com/company/surfheimco" className="text-2xl font-medium text-gray-100 hover:text-gray-400"><BsLinkedin /></a></li>
            </ul>
          </div>

          <div className="w-full md:w-1/3 xl:w-1/5 p-6">
            <h2 className="text-lg font-bold text-white">Contact us</h2>
            <ul>
              <li className="text-sm font-medium text-gray-100 hover:text-gray-400">support@surfheim.com</li>
              <li className="text-sm font-medium text-gray-100 hover:text-gray-400"><a href='/Ticket/'>Send Ticket</a></li>
            </ul>
            <h2 className="text-lg font-bold text-white pt-2">Review us</h2>
            <ul>
              <li className='text-sm font-medium text-gray-100 hover:text-gray-400'><a href='https://www.trustpilot.com/evaluate/surfheim.com?stars=5'>Trustpilot</a></li>
            </ul>
          </div>

        </div>
        <p className="text-sm font-medium font-secondary text-gray-100">Copyright surfheim &copy; 2024 - {new Date().getFullYear()}. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;