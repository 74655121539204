import React, { useState, useEffect } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import axios from 'axios';
import bitcoin from '../../../assets/coins/bitcoin.png';
import ethereum from '../../../assets/coins/ethereum.png';
import tether from '../../../assets/coins/tether.png';
import bnb from '../../../assets/coins/bnb.png';
import solana from '../../../assets/coins/solana.png';
import monero from '../../../assets/coins/monero.png';
import usdc from '../../../assets/coins/usdc.png';
import oxapay from '../../../assets/img/oxapay.png';
import btcpay from '../../../assets/img/btcpay.png';
import plisio from '../../../assets/img/plisio.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const Fund = () => {
    const [amount, setAmount] = useState('');
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(false);
    const [payData, setPayData] = useState(null);
    const [currentBalance, setCurrentBalance] = useState(0);
    const [isOpen1, setIsOpen1] = useState(true);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);


    const handleToggle1 = () => {
        setIsOpen1(!isOpen1);
        setIsOpen2(false);
        setIsOpen3(false);
      };
    
    const handleToggle2 = () => {
        setIsOpen2(!isOpen2);
        setIsOpen1(false);
        setIsOpen3(false);
    };
    
    const handleToggle3 = () => {
        setIsOpen3(!isOpen3);
        setIsOpen1(false);
        setIsOpen2(false);
    };

    const handleAmountChange = (e) => {
        const value = e.target.value;
        const regex = /^\d*\.?\d{0,2}$/;
    
        if (regex.test(value) && (value === '' || parseFloat(value) <= 999.99)) {
            setAmount(value);
        }
    };


    const handleOxaPayment = async () => {
        if (!amount || parseFloat(amount) < 1) {
            toast.warn('Minimum Top-up value is $1!', {
                position: 'top-center',
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
            return;
        }

        const token = localStorage.getItem('accessToken');

        try {
            axios.defaults.headers.common['x-access-token'] = token;
            const response = await axios.post('https://api.surfheim.com/Oxapay-Payment/', {
                "amount": parseFloat(amount),
            },{
                headers: {'Content-Type': 'application/json',}
            });
            const { payLink } = response.data;
            window.location.href = payLink;
        } catch (error) {
            toast.error('An Error Happend!', {
                position: 'top-center',
                icon: 'error',
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
        }
    };

    const handlePlisioPayment = async () => {
        if (!amount || parseFloat(amount) < 1) {
            toast.warn('Minimum Top-up value is $1!', {
                position: 'top-center',
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
            return;
        }

        const token = localStorage.getItem('accessToken');
        
        try {
            axios.defaults.headers.common['x-access-token'] = token;
            const response = await axios.post('https://api.surfheim.com/Plisio-Payment/', {
                "amount": parseFloat(amount),
        },{
            headers: {
                'Content-Type': 'application/json',
            },
        });
    
            const { payLink } = response.data;
            window.location.href = payLink;
        } catch (error) {
            toast.error('An Error Happend!', {
                position: 'top-center',
                icon: 'error',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });   
        }   
        };

    const handleBtcpayPayment = async () => {
        toast.error('Service is temporarily unavailable!', {
            position: 'top-center',
            icon: 'error',
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: 'colored',
        });
    };


    useEffect(() => {
        const storedToken = localStorage.getItem('accessToken');
        if (storedToken) {
          setToken(storedToken);
        } else {
          window.location.href = '/sign-in/';
        }
    }, []);


    useEffect(() => {
        if (token) {
          setLoading(true);
          axios.defaults.headers.common['x-access-token'] = token;
          axios.get('https://api.surfheim.com/Top-up/')
           .then(response => {
              if (response.status === 200) {
                setPayData(response.data.payData);
              }
            })
           .catch(error => {
              toast.warn('Authentication Error! Please login.', {
                position: 'top-center',
                icon: 'check',
                autoClose: 4000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
    
            setTimeout(() => {
                window.location.href = '/sign-in/';
            }, 3000);
            })
            .finally(() => {
              setLoading(false);
            });
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            axios.defaults.headers.common['x-access-token'] = token;
            axios.get('https://api.surfheim.com/Get-balance/')
            .then(response => {
                if (response.status === 200) {
                    setCurrentBalance(response.data.balance);
                }
            })
        }
      }, [token]);

    const balanceAfterTopUp = (currentBalance + (parseFloat(amount) || 0)).toFixed(2);

    return (
        <div className="container mx-auto flex flex-col items-center">
            <h1 className="h2 mt-4 lg:mt-8 text-center">Top-up Your Account</h1>
            <hr className='my-4'/>
            
            <p className="mb-4 text-justify mx-auto">
                On this page, you can top-up your account funds using any accepted payment method and view your previous top-up transactions.
            </p>

            <div className='flex flex-col lg:flex-row items-start justify-center mt-4 gap-x-6 md:gap-x-12 lg:gap-x-20 gap-y-8 md:gap-y-0'>
                <div className='flex flex-col'>
                    <div className='bg-white border-[2px] border-gray-200 rounded-xl w-[340px] sm:w-[360px] md:w-[400px] lg:w-[480px] pb-4'>
                        <div className="flex flex-col mx-6 mt-4">
                            <label className="text-sm font-medium text-dark/85">Amount to Top-up (USD)</label>
                            <input
                                type="text"
                                value={amount}
                                onChange={handleAmountChange}
                                className="border border-gray-300 rounded-lg p-2 mt-1"
                                placeholder="Enter Amount in USD"
                            />
                        </div>

                        <div className='flex flex-col justify-between p-6'>
                            <div className='flex flex-row justify-between items-center px-6'>
                                <span className='text-[16px] text-dark/85 font-semibold border-black'>Current Balance: </span>
                                <span className='text-[16px] text-dark/85 font-semibold border-black'>${currentBalance}</span>
                            </div>
                            <div className='flex flex-row justify-between items-center px-6'>
                                <span className='text-[16px] text-dark/85 font-semibold border-black'>Balance after Top-up: </span>
                                <span className='text-[16px] text-dark/85 font-semibold border-black'>${balanceAfterTopUp}</span>
                            </div>
                        </div>

                        <div className='mx-6 flex flex-col align-middle bg-accent/15 p-4 rounded-lg text-justify'>
                            <p className='text-sm font-semibold'>Note:</p>
                            <p className="text-sm font-medium text-dark/80 mt-1"> • Fees associated with the blockchain network might be applicable</p>
                            <p className="text-sm font-medium text-dark/80 mt-1"> • Payment processing usually takes just a few minutes; however, there are instances where it may take up to 24 hours.</p>
                            <p className="text-sm font-medium text-dark/80 mt-1"> • You will receive an email confirmation once your service is activated.</p>
                            <p className="text-sm font-medium text-dark/80 mt-1"> • By proceeding with your purchase, you accept our <a href='/Terms-of-service/' className='text-blue-600 underline'>Terms of Service</a>.</p>
                        </div>
                    </div>
                </div>       

                <div className="flex flex-col bg-white border-[2px] border-gray-200 rounded-lg w-[340px] sm:w-[360px] md:w-[400px] lg:w-[480px] lg:mt-0 mt-4 md:mb-4 pb-4">
                    <span className='text-[24px] text-dark font-secondary font-semibold px-6 py-4'>Pay with Preferred Option</span>
                    
                    <div className='flex flex-row justify-between items-center px-6'>
                        <span className='text-[16px] sm:text-[20px] text-dark/75 font-semibold'>Cryptocurrencies</span>
                        <div className='flex flex-row gap-x-0.5'> 
                            <img className='h-5 sm:h-6 rounded-full' src={bitcoin} alt='bitcoin'/>
                            <img className='h-5 sm:h-6 rounded-full' src={tether} alt='tether'/>
                            <img className='h-5 sm:h-6 rounded-full' src={bnb} alt='bnb'/>
                            <img className='h-5 sm:h-6 rounded-full' src={solana} alt='solana'/>
                            <img className='h-5 sm:h-6 rounded-full' src={monero} alt='monero'/>
                            <img className='h-5 sm:h-6 rounded-full' src={usdc} alt='usdc'/>
                            <img className='h-5 sm:h-6 rounded-full' src={ethereum} alt='ethereum'/>
                        </div>
                    </div>
    
                    <hr className='mx-6 my-4'/>
    
                    <div className='flex flex-col mx-6 gap-y-2'>
                        <span className='text-[20px] text-dark/85 font-semibold border-black'>Select provider:</span>
                        
                        <div className='flex flex-col gap-y-2'>
                            <div className="relative bg-light/35 rounded-xl">
                                <div className='flex flex-row justify-between items-center'>
                                    <button className="w-full font-bold"
                                        onClick={handleToggle1}>
                                            <div><img className='h-10 my-2' src={oxapay} alt='oxapay'/></div>
                                    </button>
                                    <BsChevronDown className='mr-2'/>
                                </div>
    
                                {isOpen1 && (
                                    
                                    <div className=' w-full flex flex-col border-dark' >
                                        <hr className=' my-2 border-dark/35'/>
                                        <div className='flex flex-col mx-4 mb-2'>
                                            <p className='text-sm font-medium text-dark/85'>Continue to buy VPN with oxapay</p>
                                            <p className='text-sm font-medium text-dark/85'>+20 coins are supported</p>
                                            <button onClick={handleOxaPayment} className="w-full text-md rounded-md cursor-pointer bg-accent text-white py-1  mx-auto px-4 lg:mx-22 my-2 font-secondary font-semibold">
                                                Complete top-up
                                            </button>
                                        </div>
                                    </div>
                                    )}
                            </div>
    
                            <div className="relative bg-light/35 rounded-xl">
                                <div className='flex flex-row justify-between items-center'>
                                    <button className="w-full font-bold"
                                        onClick={handleToggle3}>
                                            <div><img className='h-10 ml-3 my-1' src={plisio} alt='plisio'/></div>
                                    </button>
                                    <BsChevronDown className='mr-2'/>
                                </div>
    
                                {isOpen3 && (
                                    <div className=' w-full flex flex-col border-dark' >
                                        <hr className=' my-2 border-dark/35'/>
                                        <div className='flex flex-col mx-4 mb-2'>
                                            <p className='text-sm font-medium text-dark/85'>Continue to buy VPN with Plisio</p>
                                            <p className='text-sm font-medium text-dark/85'>+20 coins are supported</p>
                                            <button onClick={handlePlisioPayment} className="w-full text-md rounded-md cursor-pointer bg-accent text-white py-1  mx-auto px-4 lg:mx-22 my-2 font-secondary font-semibold">
                                                Complete top-up
                                            </button>
                                        </div>
                                    </div>
                                    )}
                            </div>
    
                            <div className="relative bg-light/35 rounded-xl">
                                <div className='flex flex-row justify-between items-center'>
                                    <button className="w-full font-bold" onClick={handleToggle2}>
                                        <div><img className='h-10 ml-2 my-1' src={btcpay} alt='btcpay'/></div>
                                    </button>
                                    
                                    <BsChevronDown className='mr-2'/>
                                </div>
    
                                {isOpen2 && (
                                    <div className=' w-full flex flex-col border-dark' >
                                        <hr className=' my-2 border-dark/35'/>
                                        <div className='flex flex-col mx-4 mb-2'>
                                            <p className='text-sm font-medium text-dark/85'>Continue to buy VPN with BTCpay</p>
                                            <p className='text-sm font-medium text-dark/85'>Pay with Bitcoin</p>
                                            <button onClick={handleBtcpayPayment} className="w-full text-md rounded-md cursor-pointer bg-accent text-white py-1  mx-auto px-4 lg:mx-22 my-2 font-secondary font-semibold">
                                                Complete top-up
                                            </button>
                                        </div>
                                    </div>
                                    )}
                            </div>   
                        </div>
                    </div>
                </div>                
            </div>

            <div className={`flex flex-col px-2 sm:px-8 lg:px-16 py-2 rounded-2xl my-6 shadow-lg border border-dark/25 ${!payData || payData.length === 0 ? 'w-full' : ''}`}>
                <div>
                    <p className='text-dark font-semibold mb-2'>Payments:</p>
                </div>

                {payData && payData.length > 0 ? (
                    <div className="overflow-x-auto">
                        <table className='min-w-full w-full sm:w-auto'>
                            <thead>
                            <tr className='bg-gray-100'>
                                <th className='px-1 sm:px-4 py-2 text-left text-xs sm:text-base'>Amount</th>
                                <th className='px-1 sm:px-4 py-2 text-left text-xs sm:text-base'>Status</th>
                                <th className='px-1 sm:px-4 py-2 text-left text-xs sm:text-base'>Pay Currency</th>
                                <th className='px-1 sm:px-4 py-2 text-left text-xs sm:text-base'>Pay Amount</th>
                                <th className='px-1 sm:px-4 py-2 text-left text-xs sm:text-base'>gateway</th>
                                <th className='px-1 sm:px-4 py-2 text-left text-xs sm:text-base'>Time</th>
                            </tr>
                            </thead>
                            <tbody>
                            {payData.map((pay, index) => (
                                <tr key={index} className='hover:bg-gray-50'>
                                <td className='px-1 sm:px-4 py-2 text-xs sm:text-base'>${pay.amount || 'Null'}</td>
                                <td className='px-1 sm:px-4 py-2 text-xs sm:text-base'>{pay.status|| 'Null'}</td>
                                <td className='px-1 sm:px-4 py-2 text-xs sm:text-base'>{pay.pay_currency || 'Null'}</td>
                                <td className='px-1 sm:px-4 py-2 text-xs sm:text-base'>{pay.pay_amount || 'Null'}</td>
                                <td className='px-1 sm:px-4 py-2 text-xs sm:text-base'>{pay.gateway || 'Null'}</td>
                                <td className='px-1 sm:px-4 py-2 text-xs sm:text-base'>{pay.time || 'Null'}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    ) : (
                        <p className='text-dark/85'>No Payment found.</p>
                )}
            </div>
            
            <ToastContainer />
            
        </div>
    );
};

export default Fund;

