import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Helmet } from 'react-helmet';


const AboutUS = () => {
    return (
        <div>

            <Helmet>
                <title>About Us | Surfheim VPN - Your Trusted VPN Provider</title>
                <link rel="canonical" href="https://www.surfheim.com/About-us/" />
                <meta name="description" content="Learn more about Surfheim VPN, your trusted provider for secure browsing and online privacy. Discover our mission, values, and commitment to protecting your data." />
                <meta name="keywords" content="about us, Surfheim VPN, V2ray, Vmess, Vless, Xray, VPN provider, online privacy, secure browsing, no logs policy, company mission, data protection" />
                <meta property="og:title" content="About Surfheim VPN - Your Trusted VPN Provider" />
                <meta property="og:description" content="Discover Surfheim VPN's mission and values. We are committed to providing secure browsing and protecting your online privacy with a strict no-logs policy." />
                <meta property="og:image" content="%PUBLIC_URL%/about-us-image.png" />
                <meta property="og:url" content="https://www.surfheim.com/About-us/" />
                <meta property="og:type" content="website" />
            </Helmet>

            <Header />
            <div className="md:container bg-light/25 mx-8 md:mx-auto px-4 py-8 rounded-xl my-8 text-justify">
                <h1 className="text-4xl font-semibold mb-8 text-center">
                About Us
                </h1>

                <h2 className="text-2xl font-semibold mb-4">Our Experience and Expertise</h2>
                <p className="text-gray-800 mb-8 ml-4">
                    {'Welcome to our VPN service! Since 2009, we have been utilizing and developing VPN solutions for both personal use and for companies that require robust protection against information leaks and cybersecurity threats. Our extensive experience has allowed us to understand the intricacies of internet security, enabling us to create effective solutions tailored to meet the needs of various users.'}
                </p>

                <h2 className="text-2xl font-semibold mb-4">Continuous Improvement</h2>
                <p className="text-gray-800 mb-8 ml-4">
                    {'Over the years, we have continuously improved our services based on user feedback and advancements in technology. Our commitment to excellence drives us to stay updated with the latest trends in cybersecurity, ensuring that our offerings remain relevant and effective in an ever-evolving digital landscape.'}
                </p>


                <h2 className="text-2xl font-semibold mb-4">Affordable Solutions for Everyone</h2>
                <p className="text-gray-800 mb-8 ml-4">
                    {'In 2020, we made a pivotal decision to extend our expertise beyond private use and corporate clients by launching a VPN service that is both effective and affordable for everyone. We believe that online privacy should not be a luxury but a fundamental right accessible to all. Our goal is to provide high-quality VPN services that empower individuals and organizations alike to protect their data without breaking the bank.'}
                </p>

                <h2 className="text-2xl font-semibold mb-4">Our Technology: Xray-Based VPN</h2>
                <p className="text-gray-800 mb-8 ml-4">
                    {'We are proud to offer an Xray-based VPN service, which is specifically designed to proxy internet traffic effectively. This technology is particularly known for its ability to bypass restrictive internet censorship, including the Great Firewall of China. By utilizing advanced techniques, our Xray-based VPN allows users to access blocked content while maintaining their anonymity online.'}
                </p>

                <h2 className="text-2xl font-semibold mb-4">Why Choose Us?</h2>
                <p className="text-gray-800 mb-8 ml-4">
                    {'In today’s digital landscape, protecting your data and identity has never been more crucial. Cyber threats are on the rise, and individuals need a dependable solution to safeguard their personal information. Our VPN service provides a cheap, fast, and secure connection that empowers users to browse the internet freely without compromising their privacy.'}
                </p>
                <p className="text-gray-800 mb-8 ml-4">
                    {'We understand that every user has unique needs when it comes to internet security. That’s why we offer customizable options tailored to fit your specific requirements. Whether you are looking for high-speed connections for streaming or enhanced security for sensitive transactions, we have you covered.'}
                </p>

                <h2 className="text-2xl font-semibold mb-4">Commitment to Security and Privacy</h2>
                <p className="text-gray-800 mb-8 ml-4">
                    {'At our core, we prioritize your security and privacy. Our VPN encrypts your internet traffic, making it nearly impossible for third parties to intercept or monitor your online activities. We adhere strictly to a no-logs policy, ensuring that your browsing history remains private and confidential.'}
                </p>
                <p className="text-gray-800 mb-8 ml-4">
                    {'Join us in taking control of your online presence with our reliable VPN service. Experience the freedom of unrestricted internet access while keeping your data safe from prying eyes.'}
                </p>
                
            </div>

            <Footer />
        </div>
    )
};

export default AboutUS;
