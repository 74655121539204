import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from '../../assets/img/surfheim-logo.png';
import { useNavigate } from 'react-router-dom';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfPassword, setShowConfPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [turnstileToken, setTurnstileToken] = useState('');

    const siteKey = '0x4AAAAAAAh7yiqQr45zLiE8';
  
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    useEffect(() => {
        const token = localStorage.getItem('accessToken');

        if (token) {
            axios.get('https://api.surfheim.com/authentication/', {
                headers: { 'x-access-token': token }
            }).then(response => {
                if (response.status !== 200) {
                    throw new Error('Token is not valid');
                }
            })
            .catch(error => {
                toast.error('Session expired, please sign in again.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "colored",
                });
                setTimeout(() => {
                   navigate('/Sign-in/');
                }, 2000);
            });
        } else {
            toast.error('Please sign in.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
            });
            setTimeout(() => {
                navigate('/Sign-in/');
            }, 2000);
        }
    }, [navigate]);


    useEffect(() => {
        const turnstileScript = document.createElement('script');
        turnstileScript.src = `https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback`;
        document.body.appendChild(turnstileScript);
        window.onloadTurnstileCallback = () => {
            window.turnstile.render('#turnstile-container', {
                sitekey: siteKey,
                callback: turnstileCallback,
            });
        };

        const turnstileCallback = (token) => {
            setTurnstileToken(token);
        };

        return () => {
            document.body.removeChild(turnstileScript);
        };
    }, [siteKey]);


    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleShowConfPassword = () => {
        setShowConfPassword(!showConfPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (loading || !turnstileToken) return;
        
        setLoading(true)

        if (!oldPassword) {
            toast.error('Please enter your current password.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
            });
            setTurnstileToken(null);
            setLoading(false);
            return;
        }

        if (!passwordRegex.test(newPassword)) { 
            toast.error('Password must be at least 8 characters and contain a special character, capital letter, and number', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
            });
            setTurnstileToken(null);
            setLoading(false)
            return;    
        }
        
        if (newPassword !== confirmPassword) {
            toast.error('New passwords do not match', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
            });
            setTurnstileToken(null);
            setLoading(false)
            return;
        }


        const token = localStorage.getItem('accessToken');

        if (token) {
            axios.defaults.headers.common['x-access-token'] = token;
            axios.post('https://api.surfheim.com/Change_password/', {
                "old_password": oldPassword,
                "new_password": newPassword,
                "turnstileToken": turnstileToken,
            }, { headers: { "Content-Type": "application/json" }}
        ).then(response => {
            if (response.status === 200) {
                toast.success('Password changed successfully', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "colored",
                });

                setTurnstileToken(null);
                setTimeout(() => {
                    navigate('/profile/');
                }, 3000);
                
            }
        }).catch (error => {
            if (error.response && (error.response.data['message'] === 'Token is not valid' || error.response.data['message'] === 'Token has expired')) {
                toast.error('Session expired, please sign in again.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "colored",
                });
                setTurnstileToken(null);
                setTimeout(() => {
                    navigate('/Sign-up/');
                }, 2000);
            } else {
                toast.error(error.response.data, {
                    position: "top-center",
                    icon: 'error',
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setTurnstileToken(null);
                setLoading(false)
            }
        });
        } else {
            toast.error('Error: unauthorized!', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "colored",
            });
            setTurnstileToken(null);
            setLoading(false)
        }
    };


    return (
        <div className="h-screen w-full flex justify-center items-center flex-col pb-36 md:pb-24 bg-dark">
            <div className="max-w-[320px] min-h-[440px] sm:max-w-[420px] sm:min-h-[540px] bg-active rounded-xl py-4 justify-center items-center px-8 sm:px-12">
                <img src={logo} alt="Logo" className="w-48 sm:w-64 mx-auto mb-2 py-4 sm:py-6" />
                <h2 className="font-semibold text-xl sm:text-3xl text-white tracking-tight my-4 sm:pb-4 text-center">Change Password</h2>
                <form onSubmit={handleSubmit} className='font-secondary'>
                    <div className="mb-2 relative">
                        <input 
                            type={showPassword ? 'text' : 'password'}  
                            value={oldPassword} 
                            onChange={(e) => setOldPassword(e.target.value)} 
                            className="shadow appearance-none text-gray-700 leading-tight focus:outline-none focus:shadow-outline border rounded-lg w-full align-bottom p-2 sm:p-3 border-accent" 
                            placeholder="Enter Current Password" 
                        />

                        <span className="absolute right-0 top-0 mt-3.5 mr-3 cursor-pointer text-[22px]" onClick={handleShowPassword}>
                          {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                        </span>
                    </div>

                    <div className="mb-2 relative">
                        <input 
                            type={showNewPassword ? 'text' : 'password'} 
                            value={newPassword} 
                            onChange={(e) => setNewPassword(e.target.value)} 
                            className="shadow appearance-none text-gray-700 leading-tight focus:outline-none focus:shadow-outline border rounded-lg w-full align-bottom p-2 sm:p-3 border-accent" 
                            placeholder="Enter New Password" 
                        />

                        <span className="absolute right-0 top-0 mt-3.5 mr-3 cursor-pointer text-[22px]" onClick={handleShowNewPassword}>
                          {showNewPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                        </span>

                    </div>

                    <div className="mb-2 relative">
                        <input 
                            type={showConfPassword ? 'text' : 'password'} 
                            value={confirmPassword} 
                            onChange={(e) => setConfirmPassword(e.target.value)} 
                            className="shadow appearance-none text-gray-700 leading-tight focus:outline-none focus:shadow-outline border rounded-lg w-full align-bottom p-2 sm:p-3 border-accent" 
                            placeholder="Re-enter New Password"
                        />

                        <span className="absolute right-0 top-0 mt-3.5 mr-3 cursor-pointer text-[22px]" onClick={handleShowConfPassword}>
                          {showConfPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                        </span>

                    </div>

                    <button 
                        type="submit" 
                        disabled={loading}
                        className="rounded-lg cursor-pointer bg-blue-500 border-blue-500 w-full mt-4 text-white py-2 sm:py-2.5 font-secondary font-semibold">

                        {loading ? 'Loading...' : 'Change Password'}
                    </button>

                    <div id="turnstile-container" className="mt-3 items-center"></div>
                    
                </form>
            </div>

            <div className="flex flex-col md:flex-row text-white mb-2 text-[14px] font-secondary"
                style={{position: 'fixed', bottom: '2px', display: 'flex', justifyContent: 'center', alignItems: 'center',}}>

                <div className="flex flex-row gap-x-4">
                    <div>
                        <a href="/">Home</a>
                    </div>
                    <div>
                        <a href="/Terms-of-Service/">Term of service</a>
                    </div>
                    <div>
                        <a href="/Privacy-Policy/">Privacy Policy</a>
                    </div>
                </div>

                <div className="mx-0 md:mx-16 lg:mx-36 xl:mx-44">
                    support@surfheim.com
                </div>

                <div className="mr-0 md:mr-3">
                    Copyright &copy; {new Date().getFullYear()} - All Rights Reserved
                </div>
            </div>

            <ToastContainer />

        </div>
    );
};

export default ChangePassword;