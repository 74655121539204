import React from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Hero from './Components/Hero';
import Demo from './Components/Demo';
import Pricing from './Components/Pricing';
import Feature1 from './Components/Feature1';
import Feature2 from './Components/Feature2';
import Feature3 from './Components/Feature3';
import Devices from './Components/Devices';
import Locations from './Components/Locations';
import Brief from './Components/Brief';
import { Helmet } from 'react-helmet';


const Home = () => {
  

  return (
    <div className='overflow-hidden'>

      <Helmet>
        <title>Home | Surfheim VPN - No Logs Policy, Fast & Secure Browsing for Online Privacy</title>
        <link rel="canonical" href="https://surfheim.com/" />
        <meta name="description" content="Surfheim VPN offers fast, secure, and reliable VPN services with a strict no-logs policy, military-grade encryption, and global server access for enhanced online privacy." />
        <meta name="keywords" content="VPN, secure browsing, V2ray, Vmess, Vless, Xray, online privacy, no logs policy, affordable VPN, VPN download, fast VPN, military-grade encryption, USA VPN, United states VPN, Canada VPN, Germany VPN, United Kingdom VPN, France VPN, Netherlands VPN, Sweden VPN, Turkey VPN, Poland VPN, Finland VPN, Japan VPN, UAE VPN, Brazil VPN, Hong Kong VPN, South Africa VPN, Europe VPN, Asia VPN, America VPN, Africa VPN " />
        <meta property="og:title" content="Home | Surfheim VPN - No Logs Policy & Secure Connections" />
        <meta property="og:description" content="Join Surfheim VPN for unparalleled online security and privacy. Enjoy fast connections, a strict no-logs policy, and the ability to pay with cryptocurrency for added anonymity." />
        <meta property="og:image" content="%PUBLIC_URL%/logo192.png" />
        <meta property="og:url" content="https://www.surfheim.com/" />
        <meta property="crypto:payment" content="Our VPN services can only be paid for using cryptocurrency. We accept various altcoins through multiple gateways, ensuring secure and anonymous transactions. Enjoy the benefits of lower fees, enhanced privacy, and faster processing times with crypto payments." />
      </Helmet>
    
      <Header />
      
      <Hero />
      <Demo />
      <Pricing />
      <Feature1 />
      <Feature2 />
      <Feature3 />
      <Devices />
      <Brief />
      <Locations />
      
      <Footer />

    </div>
  )
};

export default Home;