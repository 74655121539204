import React from 'react';
import briefImg from '../../../assets/img/surfheim-brief.png';

const Brief = () => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "ImageObject",
    "url": briefImg,
    "caption": "Surfheim VPN to secure connection and data encryption with no-logs policy",
    "contentUrl": briefImg,
    "width": 400, 
    "height": 310 
  };

  return (
    <section className='section bg-gradient-to-br from-active via-accent to-active/55'>
      <div className='max-w-[1340px] mx-auto px-[25px]'>
        <div className='flex flex-col lg:flex-row lg:items-start items-center lg:gap-x-[30px]'>

          <div className='max-w-[640px] mx-auto text-center my-auto'>
            <h2 className='title text-white mb-6'>Why Surfheim VPN?</h2>
            <p className='text-lg lg:text-xl text-white text-justify font-medium'>
              Surfheim offers superior security features with modern encryption algorithms that protect user data more effectively than traditional VPNs. Additionally, the advanced obfuscation techniques employed by surfheim VPN facilitate the circumvention of internet restrictions, providing users with greater freedom online. To learn more <a className='underline' href='/About-us/'>click here.</a>
            </p>
          </div>

          <div className='flex justify-between mt-6 lg:mt-0'>
            <img className='flex h-64 md:h-80' src={briefImg} alt='Surfheim VPN to secure connection and data encryption with no-logs policy' />
          </div>
        </div>
      </div>

      <script type="application/ld+json">
        {JSON.stringify(schemaMarkup)}
      </script>
      
    </section>
  );
};

export default Brief;